import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getAllUserRides } from "../../Services/UserRideService";

const UserRideTable = () => {
    const Columns = [
        {
            name: "User",
            selector: (row) => row.user?.full_name,
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Source",
            selector: (row) => row.ride?.source?.address,
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Destination",
            selector: (row) => row.ride?.destination?.address,
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Driver",
            selector: (row) => row.driver?.full_name,
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Date",
            selector: (row) => new Date(row.ride?.ride_date).toISOString().split("T")[0],
            sortable: true,
            center: false,
            minWidth: "100px",
            maxWidth: "150px",
        },
        {
            name: "Time",
            selector: (row) => row.ride?.ride_time,
            sortable: true,
            center: false,
            minWidth: "100px",
            maxWidth: "150px",
        },
        {
            name: "Amount",
            selector: (row) => `${row.ride?.amount} $`,
            sortable: true,
            center: false,
            minWidth: "100px",
            maxWidth: "150px",
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            center: false,
            minWidth: "100px",
            maxWidth: "150px",
            cell: (row) => {
                const getStyles = () => {
                    switch (row.status) {
                        case "Accepted":
                            return { backgroundColor: "green", color: "white" };
                        case "Rejected":
                            return { backgroundColor: "red", color: "white" };
                        case "Pending":
                            return { backgroundColor: "yellow", color: "black" };
                        default:
                            return { backgroundColor: "transparent", color: "black" };
                    }
                };
        
                const styles = getStyles();
        
                return (
                    <div
                        style={{
                            ...styles,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            textAlign: "center",
                        }}
                    >
                        {row.status}
                    </div>
                );
            },
        },                
    ];

    const [userRideList, setUserRideList] = useState([]);

    const getAllUserRideList = async () => {
        try {
            const res = await getAllUserRides();
            setUserRideList(res.data);
        } catch (error) {
            console.log(error);
        }
    };    

    useEffect(() => {
        getAllUserRideList();
    }, []);

    return (
        <Fragment>
            <div className="table-responsive support-table">
                <DataTable
                    columns={Columns}
                    data={userRideList}
                    striped={true}
                    center={true}
                    pagination
                />
            </div>
        </Fragment>
    );
};

export default UserRideTable;
