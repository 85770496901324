import { Breadcrumbs, H5 } from '../../AbstractElements';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { Fragment } from 'react';
import UserTable from './UserTable';

const User = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Users' parent='Dashboard' title='Users' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <H5>{'Users List'}</H5>
                <span>{'List of users'}</span>
              </CardHeader>
              <CardBody>
                <UserTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default User;
