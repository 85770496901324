import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";

const Routers = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path="/*" element={<LayoutRoutes />} />
          </>
        ) : (
          <>
            {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </>
        )}
      </Routes>
    </Suspense>
  );
};

export default Routers;
