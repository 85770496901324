import fetch from "../interceptor/fetchInterceptor";

export function getAllUserRides() {
    return fetch({
        url: "/admin/user/rides",
        method: "get",
        headers: {
            "public-request": "true",
        },
    });
}
