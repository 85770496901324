import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, Image, P } from "../AbstractElements";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { login } from "../Slices/authSlice";
import Logo from "../assets/images/city2city_logo.png";
import { adminLogin } from "../Services/AuthService";

const Signin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [togglePassword, setTogglePassword] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const res = await adminLogin({ email, password });

            if (res) {
                localStorage.setItem("token", res?.data?.token);
                dispatch(login());
                navigate("/");
                toast.success("Login successful!");
            }
        } catch (error) {
            console.log(error);
            toast.error("Please try again");
        }
    }

    return (
        <Fragment>
            <Container fluid={true} className="p-0 login-page">
                <Row>
                    <Col xs="12">
                        <div className="login-card">
                            <div className="login-main login-tab">
                                <Image
                                    attrImage={{
                                        className: "",
                                        src: `${Logo}`,
                                        alt: "",
                                    }}
                                />
                                <Form
                                    className="theme-form"
                                    onSubmit={handleLogin}
                                >
                                    <H4>Sign In</H4>
                                    <P>
                                        {"Enter your email & password to login"}
                                    </P>
                                    <FormGroup>
                                        <Label className="col-form-label">
                                            Email
                                        </Label>
                                        <Input
                                            className="form-control"
                                            type="email"
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            value={email}
                                            required
                                        />
                                    </FormGroup>

                                    <FormGroup className="position-relative">
                                        <Label className="col-form-label">
                                            Password
                                        </Label>
                                        <div className="position-relative">
                                            <Input
                                                className="form-control"
                                                type={
                                                    togglePassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                                value={password}
                                            />
                                            <div
                                                className="show-hide"
                                                onClick={() =>
                                                    setTogglePassword(
                                                        !togglePassword
                                                    )
                                                }
                                            >
                                                <span
                                                    className={
                                                        togglePassword
                                                            ? ""
                                                            : "show"
                                                    }
                                                ></span>
                                            </div>
                                        </div>
                                    </FormGroup>

                                    <div className="position-relative form-group mb-0">
                                        <div className="checkbox">
                                            <Input
                                                id="checkbox1"
                                                type="checkbox"
                                            />
                                            <Label
                                                className="text-muted"
                                                for="checkbox1"
                                            >
                                                Remember Password
                                            </Label>
                                        </div>
                                        <a className="link" href="#javascript">
                                            Forgot Password
                                        </a>
                                        <Btn
                                            attrBtn={{
                                                color: "primary",
                                                className: "d-block w-100 mt-2",
                                            }}
                                        >
                                            Sign In
                                        </Btn>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Fragment>
    );
};

export default Signin;
