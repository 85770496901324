import { Breadcrumbs, H5 } from '../../AbstractElements';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { Fragment } from 'react';
import UserRideTable from './UserRideTable';

const UserRide = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='User Rides' parent='Dashboard' title='User Rides' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <H5>{'User Rides List'}</H5>
                <span>{'List of user rides'}</span>
              </CardHeader>
              <CardBody>
                <UserRideTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default UserRide;
