import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getDriverRides } from "../../Services/DriverService";

const DriverRideTable = () => {
    const Columns = [
        {
            name: "Driver Name",
            selector: (row) => row.driver?.full_name,
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Source",
            selector: (row) => row.source?.address,
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Destination",
            selector: (row) => row.destination?.address,
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Date",
            selector: (row) => new Date(row.ride_date).toISOString().split("T")[0],
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Time",
            selector: (row) => row.ride_time,
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Amount",
            selector: (row) => `${row.amount} $`,
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Passanger Capacity",
            selector: (row) => row.passenger_capacity,
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
    ];

    const [driverRideList, setDriverRideList] = useState([]);

    const getAllDriverRideList = async () => {
        try {
            const res = await getDriverRides();
            setDriverRideList(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllDriverRideList();
    }, []);

    return (
        <Fragment>
            <div className="table-responsive support-table">
                <DataTable
                    columns={Columns}
                    data={driverRideList}
                    striped={true}
                    center={true}
                    pagination
                />
            </div>
        </Fragment>
    );
};

export default DriverRideTable;
