import fetch from "../interceptor/fetchInterceptor";

export function getAllDrivers() {
    return fetch({
        url: "/admin/drivers",
        method: "get",
        headers: {
            "public-request": "true",
        },
    });
}

export function getDriverRides() {
    return fetch({
        url: "/admin/driver/rides",
        method: "get",
        headers: {
            "public-request": "true",
        },
    });
}

export function verifyDriver(passData) {
    return fetch({
        url: "/admin/verify-driver",
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: passData,
    });
}
