import fetch from "../interceptor/fetchInterceptor";

export function adminLogin(postData) {
    return fetch({
        url: "/user/auth/admin-login",
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: postData,
    });
}
