import { Breadcrumbs, H5 } from '../../AbstractElements';
import DriverTable from './DriverTable';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { Fragment } from 'react';

const Driver = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Drivers' parent='Dashboard' title='Drivers' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <H5>{'Drivers List'}</H5>
                <span>{'List of drivers'}</span>
              </CardHeader>
              <CardBody>
                <DriverTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Driver;
