import fetch from "../interceptor/fetchInterceptor";

export function getDashboardAnalysis() {
    return fetch({
        url: "/admin/dashboard/analysis",
        method: "get",
        headers: {
            "public-request": "true",
        },
    });
}
