import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getAllDrivers, verifyDriver } from "../../Services/DriverService";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { CardBody, Col, Input, Label, Media } from 'reactstrap';

const DriverTable = () => {
    const Columns = [
        {
            name: "Driver Name",
            selector: (row) => row["full_name"],
            sortable: true,
            center: false,
            minWidth: "200px",
            maxWidth: "300px",
        },
        {
            name: "Contact",
            selector: (row) => row["phone"],
            sortable: true,
            center: false,
            minWidth: "100px",
            maxWidth: "150px",
        },
        {
            name: "Car Name",
            selector: (row) => row["car_name"],
            sortable: true,
            center: false,
            minWidth: "100px",
            maxWidth: "180px",
        },
        {
            name: "DL Number",
            selector: (row) => row["driving_licence_number"],
            sortable: true,
            center: false,
            minWidth: "100px",
            maxWidth: "180px",
        },
        {
            name: "Driving licence",
            selector: (row) => (
                <a
                    href={row["driving_licence"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <i className="fa fa-external-link" aria-hidden="true"></i>
                </a>
            ),
            sortable: true,
            center: true,
            minWidth: "50px",
            maxWidth: "150px",
        },
        {
            name: "Car document",
            selector: (row) => (
                <a
                    href={row["car_document"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <i className="fa fa-external-link" aria-hidden="true"></i>
                </a>
            ),
            sortable: true,
            center: true,
            minWidth: "50px",
            maxWidth: "150px",
        },
        {
            name: "Insurance",
            selector: (row) => (
                <a
                    href={row["car_insurance"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <i className="fa fa-external-link" aria-hidden="true"></i>
                </a>
            ),
            sortable: true,
            center: true,
            minWidth: "50px",
            maxWidth: "150px",
        },
        {
            name: "Verify",
            selector: (row) => (
                // <Form.Check
                //     type="switch"
                //     id="custom-switch"
                //     checked={row["is_driver_profile_approved"]}
                //     onChange={() => handleToggle(row)}
                // />
                <Media body className='text-end icon-state switch-outline'>
                    <Label className="switch">
                        <Input 
                            type="checkbox" 
                            checked={row["is_driver_profile_approved"]}
                            onChange={() => handleToggle(row)} 
                        />
                        <span className='switch-state bg-success'></span>
                    </Label>
                </Media>
            ),
            sortable: true,
            center: false,
            minWidth: "100px",
            maxWidth: "180px",
        },  
    ];

    const [driverList, setDriverList] = useState([]);

    const getAllDriverList = async () => {
        try {
            const res = await getAllDrivers();
            setDriverList(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleToggle = async (row) => {
        const currentStatus = row["is_driver_profile_approved"];
        const driver_id = row["_id"];
    
        Swal.fire({
            title: currentStatus ? "Do you want to remove verification?" : "Do you want to verify driver profile?",
            showCancelButton: true,
            confirmButtonText: "Save",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await verifyDriver({ driver_id , is_driver_profile_approved: !currentStatus });
                    console.log("ENVVVVVVV  ", process.env.NODE_ENV)

                    if (res.success) {
                        Swal.fire("Saved!", "The changes have been saved successfully.", "success");
                    }
                } catch (error) {
                    console.error("Error updating verification status:", error);
                    Swal.fire("Error", "Failed to save changes. Please try again.", "error");
                } finally {
                    getAllDriverList();
                }
            }
        });
    };

    useEffect(() => {
        getAllDriverList();
    }, []);

    return (
        <Fragment>
            <div className="table-responsive support-table">
                <DataTable
                    columns={Columns}
                    data={driverList}
                    striped={true}
                    center={true}
                    pagination
                />
            </div>
        </Fragment>
    );
};

export default DriverTable;
