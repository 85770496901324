import { Breadcrumbs, H5 } from '../../AbstractElements';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { Fragment } from 'react';
import DriverRideTable from './DriverRideTable';

const DriverRides = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Driver Rides' parent='Dashboard' title='Driver Rides' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <H5>{'Driver Rides'}</H5>
                <span>{'List of Driver Rides'}</span>
              </CardHeader>
              <CardBody>
                <DriverRideTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default DriverRides;
