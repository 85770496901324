const NODE_ENV = process.env.NODE_ENV;
let API_BASE_URL = '';

if (NODE_ENV === "development") {
    // API (Development)
    API_BASE_URL = "http://localhost:3010/api/v1/";

} else {
    // API (Production)
    API_BASE_URL = "https://city2city.kretosstechnology.com/api/v1/";

}

export default API_BASE_URL;
